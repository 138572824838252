/* global Masonry */
import EmblaCarousel from 'embla-carousel';
export { LoadOMatic } from './vendor/load-o-matic.js';
import { setupShareLinks, getQueryObject, getQueryString } from './helpers.js';
import './vendor/masonry.pkgd.min.js';
import './vendor/ajax.min.js';
import './vendor/respond.min.js';

import './vendor/polyfills/object-fit-polyfill.js';
import './vendor/polyfills/classlist.min.js';
import './vendor/polyfills/eventlistener.min.js';

import './vendor-solo/html5shiv.min.js';

export * from './CookieConsentManager.js';

'use strict';

function getWindowWidth() {
	return window.innerWidth;
}

( () => {
	// hide header & footer in iframe
	const window_url = window.location.href;
	if ( window_url.indexOf( 'in-iframe' ) > -1 ) {
		document.querySelector( '.section--header' ).style.display = 'none';
		document.querySelector( '.section--banner' ).style.display = 'none';
		document.querySelector( '.section--partners' ).style.display = 'none';
		document.querySelector( '.section--footer' ).style.display = 'none';
	}
} )();

( () => {
	const elem = document.querySelector( '.masonry-grid' );
	if ( !elem ) {
		return;
	}
	new Masonry( elem, {
		// options
		itemSelector: '.masonry-grid__item',
		transitionDuration: 0
	} );
} )();

( () => {
	// Focus on search
	const input = document.querySelector( '.search__input' );
	if ( !input ) {
		return;
	}
	window.setTimeout( () => {
		input.focus();
	}, 250 );
} )();

( () => {
	const navToggler = document.getElementById( 'js-nav-toggle' );
	const navItems = document.querySelectorAll( '.nav__item' );
	navToggler.addEventListener( 'click', () => {
		document.body.classList.remove( 'show-cookie-notice' );
		document.body.classList.toggle( 'nav-open' );
		( function revealNavItem( i = 0 ) {
			setTimeout( () => {
				navItems[i].classList.add( 'visible' );
				i++;
				if ( i < navItems.length ) {
					revealNavItem( i );
				}
			}, 100 );
		} )( 0 );
	} );
} )();

( () => {
	// Toggle handling
	function doToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		for ( let i = 0; i < togglees.length; i += 1 ) {
			togglees[i].classList.toggle( 'toggled', !togglees[i].classList.contains( 'toggled' ) );
		}
		toggler.classList.toggle( 'toggler--toggled', !toggler.classList.contains( 'toggler--toggled' ) );
	}
	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	const dropdown_buttons = document.querySelectorAll( '.dropdown__button' );
	if ( !dropdown_buttons ) {
		return;
	}
	for ( let i = 0; i < dropdown_buttons.length; i++ ) {
		dropdown_buttons[i].addEventListener( 'click', ( e ) => {
			const parent = e.target.parentNode;
			const content = parent.querySelector( '.dropdown__content' );
			closeAllDropdowns( content );
			content.setAttribute( 'aria-expanded', true );
			content.setAttribute( 'aria-hidden', false );
			parent.classList.toggle( 'dropdown--open' );
		} );
	}

	function closeAllDropdowns( keep = null ) {
		const dropdowns = document.querySelectorAll( '.dropdown__content' );
		dropdowns.forEach( ( dropdown ) => {
			dropdown.setAttribute( 'aria-hidden', true );
			dropdown.setAttribute( 'aria-expanded', false );
			if ( dropdown !== keep ) {
				const parent = dropdown.parentNode;
				parent.classList.remove( 'dropdown--open' );
			}
		} );
	}

	window.addEventListener( 'resize', () => {
		closeAllDropdowns();
	} );

} )();

( () => {
	window.objectFitPolyfill();
} )();

( () => {
	// Get all links
	const map_anchors = document.querySelectorAll( '.location-link' );
	for ( let i = 0; i < map_anchors.length; i++ ) {
		const id = map_anchors[i].getAttribute( 'data-country-code' );
		const url = map_anchors[i].getAttribute( 'href' );
		if ( !id ) {
			continue;
		}
		const path = document.getElementById( id.toLowerCase() );
		path.classList.add( 'active-country' );
		path.addEventListener( 'click', () => {
			window.location = url;
		} );
		map_anchors[i].addEventListener( 'mouseenter', handleHover( path, true ) );
		map_anchors[i].addEventListener( 'mouseleave', handleHover( path, false ) );
	}

	function handleHover( path, entering ) {
		return function() {
			if ( entering ) {
				path.classList.add( 'hovered' );
				return;
			}
			path.classList.remove( 'hovered' );
		};
	}

} )();

( () => { //category slider
	const emblaNodes = document.getElementsByClassName( 'embla' );
	const options = { loop: false, align: 'start', containScroll: 'keepSnaps' };
	const overflowOptions = { loop: false, align: 'start', containScroll: 'trimSnaps' };
	if ( emblaNodes == null ) {
		return;
	}

	//specify embla carousels for all classes containing embla name
	const slider = [];
	for ( let i = 0; i < emblaNodes.length; i++ ) {
		if ( emblaNodes[i].classList.contains( 'embla--overflow' ) ) {
			slider[i] = new EmblaCarousel( emblaNodes[i], overflowOptions );
			continue;
		}
		slider[i] = new EmblaCarousel( emblaNodes[i], options );
	}

	const buttons_prev = document.getElementsByClassName( 'arrow-button--prev' );
	const buttons_next = document.getElementsByClassName( 'arrow-button--next' );

	//call button functions
	for ( let i = 0; i < emblaNodes.length; i++ ) {
		buttons_prev[i].addEventListener( 'click', () => {
			slider[i].scrollPrev();
		}, false );
		buttons_next[i].addEventListener( 'click', () => {
			slider[i].scrollNext();
		}, false );

		//modify button styles
		resetButtonState( buttons_prev[i], slider[i].canScrollPrev() );
		resetButtonState( buttons_next[i], slider[i].canScrollNext() );

		slider[i].on( 'select', () => {
			resetButtonState( buttons_prev[i], slider[i].canScrollPrev() );
			resetButtonState( buttons_next[i], slider[i].canScrollNext() );
		} );
	}
	window.addEventListener( 'resize', () => {
		for ( let i = 0; i < slider.length; i++ ) {
			const embla = slider[i];
			if ( getWindowWidth() < 1049 ) {
				embla.reInit( { containScroll: 'keepSnaps' } );
			}
		}
	} );

	function resetButtonState( element, state ) {
		if ( state ) {
			element.classList.add( 'arrow-button--enabled' );
		} else {
			element.classList.remove( 'arrow-button--enabled' );
		}
	}

} )();

( () => {
	// ARIA expanders
	function handleExpanderClick( expander, expandable ) {
		return () => {
			if ( expander.getAttribute( 'aria-expanded' ) === 'true' ) {
				expander.setAttribute( 'aria-expanded', 'false' );
				expandable.setAttribute( 'aria-hidden', 'true' );
				return;
			}
			expander.setAttribute( 'aria-expanded', 'true' );
			expandable.setAttribute( 'aria-hidden', 'false' );
		};
	}

	const expanders = document.querySelectorAll( '[data-aria-expander-for]' );
	for ( let i = 0; i < expanders.length; i++ ) {
		const expandable = document.getElementById(
			expanders[i].dataset.ariaExpanderFor
		);
		if ( !expandable ) {
			continue;
		}
		expandable.setAttribute( 'aria-hidden', 'true' );
		expanders[i].addEventListener( 'click', handleExpanderClick( expanders[i], expandable ) );
	}
} )();

( () => {
	// Publication info box
	const triggers = document.querySelectorAll( '.trigger-overlay' );
	if ( !triggers ) {
		return;
	}

	triggers.forEach( trigger => {
		const overlay = document.getElementById( trigger.dataset.overlayId );
		const overlay_close = overlay.querySelector( '.overlay__close' );
		trigger.addEventListener( 'click', () => {
			toggleVisible( overlay );
		} );

		overlay_close.addEventListener( 'click', () => {
			toggleVisible( overlay );
		} );

	} );

	function toggleVisible( overlay ) {
		document.body.classList.toggle( 'overlay-open' );
		overlay.classList.toggle( 'open' );
	}

} )();

( () => {
	// Fitering
	const search_components = document.querySelectorAll( '.project-filter' );
	if ( !search_components ) {
		return;
	}

	search_components.forEach( search => {
		const form = search.querySelector( 'form' );
		const filter_groups = search.querySelectorAll( '.project-filters__group' );
		const reset_btns = document.querySelectorAll( '.project-filters__reset' );
		const toggles = search.querySelectorAll( '.project-filter__toggle' );

		for ( let i = 0; i < toggles.length; i++ ) {
			const toggle = toggles[i];
			const panel = document.getElementById( toggle.getAttribute( 'aria-controls' ) );
			toggle.addEventListener( 'click', function() {
				if ( this.classList.contains( 'toggled' ) ) {
					closeAllPanels();
					return;
				}
				closeAllPanels();
				this.classList.add( 'toggled' );
				this.setAttribute( 'aria-expanded', true );
				panel.classList.add( 'active' );
			} );
		}

		function closeAllPanels() {
			toggles.forEach( toggle => {
				const panel = document.getElementById( toggle.getAttribute( 'aria-controls' ) );
				toggle.setAttribute( 'aria-expanded', false );
				toggle.classList.remove( 'toggled' );
				panel.classList.remove( 'active' );
			} );
		}

		filter_groups.forEach( filter_set => {
			const filter_inputs = filter_set.querySelectorAll( 'input' );

			filter_inputs.forEach( ( input ) => {
				input.onchange = () => {
					submitForm( form );
				};
			} );
		} );

		if ( reset_btns.length ) {
			reset_btns.forEach( btn => {
				btn.onclick = () => {
					filter_groups.forEach( group => {
						const filter_inputs = group.querySelectorAll( 'input' );
						filter_inputs.forEach( input => {
							input.checked = false;
						} );
					} );
					form.submit();
				};
			} );
		}

		form.addEventListener( 'submit', ( e ) => {
			e.preventDefault();
			submitForm( form );
		} );

	} );

	function submitForm( form ) {
		const query_string = `${ '?' + new URLSearchParams( new FormData( form ) ) }`;
		const query_object = getQueryObject( query_string );
		const reformatted_query_string = getQueryString( query_object );
		window.location = form.action + reformatted_query_string;
	}

} )();

setupShareLinks();