export function setupShareLinks( selector = '.social-share' ) {
	const btns = document.querySelectorAll( selector );
	if ( !btns[0] ) {
		return;
	}

	for ( let i = 0; i < btns.length; i++ ) {
		btns[i].addEventListener( 'click', handleSocialShare( btns[i] ) );
	}

	function handleSocialShare( btn ) {
		return ( e ) => {
			e.preventDefault();
			popUpWindow( btn.href );
		};
	}
	function popUpWindow( url ) {
		const popup_width = 550;
		const popup_height = 420;
		const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
		const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
		const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
		if ( window.focus ) {
			new_window.focus();
		}
		return false;
	}
}

export function getQueryObject( query_string ) {
	const query_object = {};
	const pairs = ( query_string[0] === '?' ? query_string.substr( 1 ) : query_string ).split( '&' );
	for ( let i = 0; i < pairs.length; i++ ) {
		const pair = pairs[i].split( '=' );
		if ( pair !== '' ) {
			const key = decodeURIComponent( pair[0] );
			const value = decodeURIComponent( pair[1] || '' );
			if ( !( query_object[key] instanceof Array ) ) {
				query_object[key] = [];
			}
			query_object[key].push( value );
			continue;
		}
	}
	return query_object;
}

export function getQueryString( query_object ) {
	const query_pieces = [];
	for ( const key in query_object ) {
		if ( !Object.prototype.hasOwnProperty.call( query_object, key ) ) {
			continue;
		}
		if ( query_object[key] instanceof Array ) {
			query_pieces.push( '&' + key.replace( '[]', '' ) + '='  );
			query_pieces.push( ( query_object[key][0] ? query_object[key][0] : '' ) );
			for ( let i = 1; i < query_object[key].length; i++ ) {
				query_pieces.push( ( query_object[key][i] ? '+' + query_object[key][i] : '' ) );
			}
			continue;
		}
	}

	if ( query_pieces.length === 0 ) {
		return '';
	}

	return '?' + query_pieces.join( '' );
}
