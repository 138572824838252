export class LoadOMatic {
	constructor( options ) {
		this.current_page = options.currentPage || 1;
		this.total_pages = options.totalPages;
		this.load_url = options.loadUrl;
		this.extra_query = options.extraQuery || '';
		this.next_page = this.current_page + 1;
		this.handle_trigger_dly = 0;

		this.append_container = document.querySelector( '.loadomatic' );
		this.message = document.querySelector( '.loadomatic__message' );
		this.trigger = document.querySelector( '.loadomatic__trigger' );

		this.callbacks = [];

		// initial load
		this.trigger.classList.add( 'loadomatic__trigger--disabled' );
		this.handleTrigger( 0 );
	}

	loadMore() {
		let _this = this;
		_this.trigger.classList.add( 'loadomatic__trigger--disabled' );
		this.trigger.innerHTML = 'Loading content...';
		ajax( {
			type: 'get',
			url: _this.urlCreator( _this.next_page, true ),
			data: {},
			timeout: 5000,
			onSuccess: ( data ) => {
				_this.current_page++;
				_this.next_page = _this.current_page + 1;
				_this.append_container.innerHTML += data;
				for ( let i = 0; i < _this.callbacks.length; i++ ) _this.callbacks[i]();
				_this.handleTrigger( _this.handle_trigger_dly );
				if ( window.history.pushState ) window.history.pushState( null, null, _this.urlCreator( _this.current_page, false ) );
				this.trigger.innerHTML = 'Load more';
			},
			onFailure: _this.handleFailure()
		} );
	}

	handleTrigger( delay ) {
		if ( this.total_pages <= this.current_page ) {
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.classList.add( 'loadomatic__message--hidden' );
			return;
		}
		this.trigger.classList.remove( 'loadomatic__trigger--hidden' );
		setTimeout( () => {
			this.trigger.classList.remove( 'loadomatic__trigger--disabled' );
		}, delay );
	}

	handleFailure() {
		return () => {
			this.trigger.innerHTML = 'Load more';
			this.message.classList.remove( 'loadomatic__message--hidden' );
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.querySelector( 'p' ).innerHTML = 'Sorry, there was a problem retrieving content.';
		}
	}

	urlCreator( page, loadmore ) {
		// regex replaces should be dealt with before getting to this point, but just in case
		let extra_query = this.extra_query.replace( /&{0,1}page=\d+/, '' ).replace( /amp;/, '' );
		return ( this.load_url + '?' + ( extra_query.length ? extra_query + '&' : '' ) + 'page=' + page + ( loadmore ? '&loadmore=true' : '' ) );
	}

	addCallback( fx ) {
		this.callbacks.push( fx );
	}
};
